<template>
  <DefaultTemplate v-if="loading">
    <div class="box-name-page">
      <div>
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="set-breadcrumb"
        >
          <el-breadcrumb-item :to="{ path: '/user/detail/' + criterionId }"
            >จัดการข้อมูลผู้ประกอบการ</el-breadcrumb-item
          >
          <el-breadcrumb-item>ข้อมูลสินเชื่อ</el-breadcrumb-item>
        </el-breadcrumb>
        <p class="title mg-t-4">ข้อมูลสินเชื่อ</p>
      </div>
    </div>
    <el-row :gutter="30">
      <el-col
        :span="24"
        v-for="(row, index) in dataCredit"
        :key="index"
        class="mg-y-5 pos-relative"
      >
        <CardBlog
          :data="row"
          :page="`userCredit`"
          @morePicture="openDialogPicture"
        />
      </el-col>
    </el-row>
    <el-dialog
      class="dialog-custom user-list"
      v-if="dialogPicture"
      :visible.sync="dialogPicture"
      width="70%"
      top="10vh"
      center
    >
      <img :src="imgMore" alt="" class="w-100" />
    </el-dialog>
  </DefaultTemplate>
</template>

<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import CardBlog from "@/components/card/CardBlog";
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";

export default {
  components: {
    DefaultTemplate,
    CardBlog,
  },
  data() {
    return {
      loading: false,
      wordCredit: "",
      dataCredit: [
        {
          _id: 1,
          title: "สินเชื่อสำหรับธุรกิจขนาดใหญ่",
          subTitle:
            "ต้องการให้ทางธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย ติดต่อ",
          img: "credit/credit01.png",
          bussinessSize: ["L"],
          bussinessGrade: ["A", "B", "C"],
          content:
            "สินเชื่อหมุนเวียนก่อนและหลังการส่งออก เพื่อเสริมสภาพคล่อง ในการส่งออก-นำเข้า สำหรับผู้ประกอบการขนาดกลาง (Amazing M) ",
          expTime: "2021-05-06",
          exp: false,
          name: "พัชรธิดา คชณาวิวัฒน์",
          phone: "08712345678",
          email: "Phatcharatida@gmail.com",
          branchContact: "สำนักงานใหญ่ อารีย์",
        },
        {
          _id: 2,
          title:
            "สินเชื่อเอ็กซิมเสริมทุนธุรกิจขนาดกลาง (EXIM Amazing M Credit)",
          subTitle:
            "ต้องการให้ทางธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย ติดต่อ",
          img: "credit/credit02.png",
          bussinessSize: ["M"],
          bussinessGrade: ["A"],
          content:
            "สินเชื่อหมุนเวียนก่อนและหลังการส่งออก เพื่อเสริมสภาพคล่องในการส่งออก-นำเข้า สำหรับผู้ประกอบการขนาดกลาง (Amazing M) ",
          expTime: "2021-05-06",
          exp: false,
          name: "พัชรธิดา คชณาวิวัฒน์",
          phone: "08712345678",
          email: "Phatcharatida@gmail.com",
          branchContact: "สำนักงานใหญ่ อารีย์",
        },
        {
          _id: 3,
          title:
            "สินเชื่อเอ็กซิมเสริมทุนธุรกิจขนาดกลาง (EXIM Amazing M Credit)",
          subTitle:
            "ต้องการให้ทางธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย ติดต่อ",
          img: "credit/credit03.png",
          bussinessSize: ["L"],
          bussinessGrade: ["B"],
          content:
            "สินเชื่อหมุนเวียนก่อนและหลังการส่งออกเหมาะสำหรับผู้ประกอบการขนาดกลางที่ต้องการเงินทุน เพื่อเสริมสภาพคล่องในการส่งออก - นำเข้า",
          expTime: "2021-05-06",
          exp: false,
          name: "พัชรธิดา คชณาวิวัฒน์",
          phone: "08712345678",
          email: "Phatcharatida@gmail.com",
          branchContact: "สำนักงานใหญ่ อารีย์",
        },
        {
          _id: 4,
          title: "สินเชื่อส่งออกเพิ่มค่า(EXIM Value Added Credit)",
          subTitle:
            "ต้องการให้ทางธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย ติดต่อ",
          img: "credit/credit04.png",
          bussinessSize: ["S", "M"],
          bussinessGrade: ["B"],
          content:
            "สินเชื่อหมุนเวียนเพื่อการส่งออก เหมาะสำหรับผู้ประกอบการ SMEs ที่เริ่มต้นส่งออก หรือต้องการขยายตลาดส่งออกในทุกกลุ่มอุตสาหกรรม",
          expTime: "2021-05-06",
          exp: false,
          name: "พัชรธิดา คชณาวิวัฒน์",
          phone: "08712345678",
          email: "Phatcharatida@gmail.com",
          branchContact: "สำนักงานใหญ่ อารีย์",
        },
        {
          _id: 5,
          title: "สินเชื่อ EXIM4SMEs Plus",
          subTitle:
            "ต้องการให้ทางธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย ติดต่อ",
          img: "credit/credit05.png",
          bussinessSize: ["S"],
          bussinessGrade: ["A"],
          content:
            "สินเชื่อหมุนเวียนและเสริมสภาพคล่องในการดำเนินธุรกิจ เพื่อสนับสนุนผู้ประกอบการ SMEs ที่เป็นผู้ส่งออก ที่มีมูลค่าการส่งออกต่อปีไม่เกิน 200 ล้านบาท ",
          expTime: "2021-05-06",
          exp: true,
          name: "พัชรธิดา คชณาวิวัฒน์",
          phone: "08712345678",
          email: "Phatcharatida@gmail.com",
          branchContact: "สำนักงานใหญ่ อารีย์",
        },
        {
          _id: 6,
          title: "สินเชื่อส่งออกเพิ่มค่า(EXIM Value Added Credit)",
          subTitle:
            "ต้องการให้ทางธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย ติดต่อ",
          img: "credit/credit04.png",
          bussinessSize: ["S"],
          bussinessGrade: ["B"],
          content:
            "สินเชื่อหมุนเวียนเพื่อการส่งออก เหมาะสำหรับผู้ประกอบการ SMEs ที่เริ่มต้นส่งออก หรือต้องการขยายตลาดส่งออกในทุกกลุ่มอุตสาหกรรม",
          expTime: "2021-05-06",
          exp: true,
          name: "พัชรธิดา คชณาวิวัฒน์",
          phone: "08712345678",
          email: "Phatcharatida@gmail.com",
          branchContact: "สำนักงานใหญ่ อารีย์",
        },
        {
          _id: 7,
          title: "สินเชื่อส่งออกสุขสุด สุด (EXIM Happiest Credit)",
          subTitle:
            "ต้องการให้ทางธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย ติดต่อ",
          img: "credit/credit06.png",
          bussinessSize: ["S"],
          bussinessGrade: ["C"],
          content:
            "สินเชื่อหมุนเวียนเพื่อการส่งออก สำหรับกลุ่มผู้ประกอบการ Start-up และกลุ่มผู้ประกอบการ SMEs ที่เริ่มต้นส่งออก",
          expTime: "2021-05-06",
          exp: false,
          name: "พัชรธิดา คชณาวิวัฒน์",
          phone: "08712345678",
          email: "Phatcharatida@gmail.com",
          branchContact: "สำนักงานใหญ่ อารีย์",
        },
        {
          _id: 8,
          title: "สินเชื่อสำหรับธุรกิจขนาดใหญ่",
          subTitle:
            "ต้องการให้ทางธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย ติดต่อ",
          img: "credit/credit01.png",
          bussinessSize: ["L"],
          bussinessGrade: ["A", "B", "C"],
          content:
            "สินเชื่อหมุนเวียนก่อนและหลังการส่งออก เพื่อเสริมสภาพคล่อง ในการส่งออก-นำเข้า สำหรับผู้ประกอบการขนาดกลาง (Amazing M) ",
          expTime: "2021-05-06",
          exp: false,
          name: "พัชรธิดา คชณาวิวัฒน์",
          phone: "08712345678",
          email: "Phatcharatida@gmail.com",
          branchContact: "สำนักงานใหญ่ อารีย์",
        },
        {
          _id: 9,
          title:
            "สินเชื่อเอ็กซิมเสริมทุนธุรกิจขนาดกลาง (EXIM Amazing M Credit)",
          subTitle:
            "ต้องการให้ทางธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย ติดต่อ",
          img: "credit/credit02.png",
          bussinessSize: ["M"],
          bussinessGrade: ["A"],
          content:
            "สินเชื่อหมุนเวียนก่อนและหลังการส่งออก เพื่อเสริมสภาพคล่องในการส่งออก-นำเข้า สำหรับผู้ประกอบการขนาดกลาง (Amazing M) ",
          expTime: "2021-05-06",
          exp: false,
          name: "พัชรธิดา คชณาวิวัฒน์",
          phone: "08712345678",
          email: "Phatcharatida@gmail.com",
          branchContact: "สำนักงานใหญ่ อารีย์",
        },
        {
          _id: 10,
          title:
            "สินเชื่อเอ็กซิมเสริมทุนธุรกิจขนาดกลาง (EXIM Amazing M Credit)",
          subTitle:
            "ต้องการให้ทางธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย ติดต่อ",
          img: "credit/credit03.png",
          bussinessSize: ["L"],
          bussinessGrade: ["B"],
          content:
            "สินเชื่อหมุนเวียนก่อนและหลังการส่งออกเหมาะสำหรับผู้ประกอบการขนาดกลางที่ต้องการเงินทุน เพื่อเสริมสภาพคล่องในการส่งออก - นำเข้า",
          expTime: "2021-05-06",
          exp: false,
          name: "พัชรธิดา คชณาวิวัฒน์",
          phone: "08712345678",
          email: "Phatcharatida@gmail.com",
          branchContact: "สำนักงานใหญ่ อารีย์",
        },
      ],
      imgMore: "",
      dialogPicture: false,
      juristicId: this.$route.params.id,
      criterionId: this.$route.params.criterionId,
      branch: this.$route.params.branch,
    };
  },
  mounted() {
    this.getCredit();
  },
  methods: {
    openDialogPicture(getImg) {
      this.imgMore = getImg;
      this.dialogPicture = true;
    },
    async getCredit() {
      try {
        HTTP.defaults.headers.common["Authorization"] = `Bearer ${this.token}`;
        var res = null;
        if (this.branch == "TERAK") {
          let obj = {
            juristicId: this.juristicId,
            criterionId: this.criterionId,
          };
          res = await this.$store.dispatch("user/fetchInterestCredit", obj);
        } else if (this.branch == "Channel Integration") {
          let obj = {
            juristicId: this.juristicId,
            criterionId: this.criterionId,
            type: "credit",
          };
          res = await this.$store.dispatch(
            "user/fetchChannelInterestProduct",
            obj
          );
        }

        if (typeof res == "object") {
          if (res.success) this.dataCredit = res.obj;
        }

        this.loading = true;
        return;
      } catch (e) {
        console.log("err: ", e);
        this.loading = true;
        return;
      }

      // const response = await HTTP.post(
      //   "user/interest/credit",
      //   request,
      //   config
      // ).catch((e) => {
      //   return {
      //     data: {
      //       data: e,
      //       success: false,
      //     },
      //   };
      // });
      // // let arrCredit = this.dataCredit.filter((a) => a._id == 1);
      // this.dataCredit = response.data.obj;
      // this.loading = true;
      // return response;
    },
  },
  computed: {
    ...mapState({
      token: (state) => state._token,
    }),
  },
};
</script>
